import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react"


import { Header } from "./components/Header.js";
import { Footer } from "./components/Footer.js";

import { Home } from "./views/Home.js";
import { Recipe } from "./views/Recipe.js";

import { queryRecipes } from "./reducers/recipes.js";

const theme = extendTheme({
  fonts: {
    heading: "Annie Use Your Telescope",
    body: "Annie Use Your Telescope",
  },
  styles: {
    global: {
      body: {
        bgImage: "url(/img/bg.jpg)",
        bgSize: "300%",
      },
    },
  },
});

// TODO switch to title_late.png when late at night (local time)

function App() {
  const dispatch = useDispatch();
    
  useEffect(() => {
    dispatch(queryRecipes());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/recipe/:slug">
          <Recipe />
        </Route>
        <Redirect to="/" />
      </Switch>
      <Footer />
    </ChakraProvider>
  );
}

export default App;
