import React from "react";
import {
  Image,
  Center,
  Box,
  HStack,
  Button,
  Input,
  InputGroup,
  InputRightAddon,
  useBreakpointValue,
} from "@chakra-ui/react"

import { Link } from "react-router-dom";

export const Header = props => {
  const [isSearching, setSearching] = React.useState(false);
  const toggleSearching = () => setSearching(!isSearching);

  const hideButton = useBreakpointValue([true, false, false, false]);

  const [searchValue, setSearchValue] = React.useState("")
  const handleSearchValueChange = (event) => {
    setSearchValue(event.target.value)
  }

  const searchButton = (
    <Button
      variant="ghost"
      colorScheme="black"
      fontSize={["xl", "4xl", "4xl", "4xl"]}
      onClick={toggleSearching}
    >
      {isSearching ? "back" : "search"}
    </Button>
  );

  return (
    <>
      <Link to="/">
          <Center>
            <Image
              w={["75vw", "75vw", "45vw", "35vw"]}
              pt="50px"
              src="/img/title.png"
            />
          </Center>
      </Link>
      <Box
        borderWidth="3px"
        className="drawn_box_menu"
        bgImage="url(/img/menu_bg.png)"
        bgSize="75%"
        bgRepeat="repeat"
        bgPosition="center"
      >
        <Center>
          <HStack
            mt="10px"
            mb="10px"
            spacing={["0px", "10px", "10vw", "10vw"]}
          >
            { hideButton && isSearching ? (<></>) : (
              <>
                <Button
                  variant="ghost"
                  colorScheme="black"
                  fontSize={["xl", "4xl", "4xl", "4xl"]}
                >
                  by ingredients
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="black"
                  fontSize={["xl", "4xl", "4xl", "4xl"]}
                >
                  what can I prepare
                </Button>
              </>
            )}
            { isSearching ? (
              <InputGroup w={["90vw", "inherit", "inherit", "inherit"]}>
                <Box as={Input}
                  fontWeight="semibold"
                  fontSize={["xl", "4xl", "4xl", "4xl"]}
                  placeholder="TODO random cockttail name"
                  className="drawn_box drawn_box_16"
                  borderWidth="3px"
                  borderColor="black"
                  value={searchValue}
                  onChange={handleSearchValueChange}
                  autoFocus
                />
                <InputRightAddon>
                  {searchButton}
                </InputRightAddon>
              </InputGroup>
            ) : searchButton}
          </HStack>
        </Center>
      </Box>
    </>
  );
};
