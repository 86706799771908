import React from 'react';
import { CircularProgress } from '@chakra-ui/react';

export const Loader = props => {
  return (
    <CircularProgress
      isIndeterminate
    />
  );
};
