import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import persistedReducer from './reducers/index.js';

const store = createStore(persistedReducer, composeWithDevTools(
  applyMiddleware(thunk),
));

const persistor = persistStore(store);

export {
  store,
  persistor,
};

