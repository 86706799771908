import produce from "immer";
import { get } from "axios";

// Actions types
export const QUERY_RECIPES = "recipes/QUERY_RECIPES";
export const QUERY_DETAILS = "recipes/QUERY_DETAILS";

export const initialState = {
  recipes: {},
  details: {},
};

export default function reducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case QUERY_RECIPES:
        draft.recipes = action.recipes;
        break;
      case QUERY_DETAILS:
        draft.details[action.details.slug] = action.details;
        break;
      default:
        break;
    }
  });
}

// Helper


// Functions
export function queryRecipes() {
  return async (dispatch) => {
    const recipes = await get("/api/recipe/");
    const data = recipes.data.reduce((a,x) => ({...a, [x.slug]: x}), {});
    dispatch({ type: QUERY_RECIPES, recipes: data, raw: recipes.data });
  };
}

export function queryDetails(slug) {
  return async (dispatch) => {
    const details = await get(`/api/recipe/${slug}`);
    dispatch({ type: QUERY_DETAILS, details: details.data });
  };
}
