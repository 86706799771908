import React from 'react';
import {
  Box,
  Badge,
  Image,
  AspectRatio,
} from "@chakra-ui/react"

import { Link } from "react-router-dom";

import { cyrb53 } from "../utils/hash.js"

export const RecipeCard = props => {
  const { recipe } = props;

  const hash = cyrb53(recipe.name);
  const border_class = `grow drawn_box drawn_box_${hash % 24}`;

  return (
    <Link to={`/recipe/${recipe.slug}/`}>
      <Box
        h="100%"
        w="100%"
        borderWidth="3px"
        m="5px"
        overflow="hidden"
        className={border_class}
        backgroundImage="url(/img/bg_card.jpg)"
        backgroundSize="100%"
      >
        <AspectRatio ratio={1} m="10px">
          <Image src={recipe.photo_url} />
        </AspectRatio>
        <Box ml="10px" mr="10px">
          <Box d="flex" alignItems="baseline">
            <Badge borderRadius="full" px="2" pt="1" colorScheme="teal">
              New
            </Badge>
            <Box
              color="gray.500"
              fontWeight="semibold"
              letterSpacing="wide"
              fontSize="xs"
              textTransform="uppercase"
              ml="2"
            >
                1 foo &bull; 42 bar
            </Box>
          </Box>

          <Box
            mt="1"
            fontSize={["2xl", "4xl", "4xl", "4xl"]}
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            isTruncated
          >
            {recipe.name}
          </Box>

          <Box
            fontSize={["lg", "xl", "xl", "xl"]}
            fontWeight="500"
            noOfLines={2}
            textAlign="justify"
            lineHeight="1"
            fontStyle="italic"
            pr="1"
          >
            {recipe.short_description}
          </Box>

        </Box>
      </Box>
    </Link>
  );
};
