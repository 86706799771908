import React from "react";
import {
  Box,
} from "@chakra-ui/react"

export const Footer = props => {
  return (
    <Box h="50px">
    </Box>
  );
};
