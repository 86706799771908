import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  UnorderedList,
  ListItem,
  Box,
  Center,
  Heading,
  Image,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react"
import ReactMarkdown from 'react-markdown'
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';

import { useParams } from "react-router-dom";

import { queryDetails } from "../reducers/recipes.js";

const markdownTheme = {
  p: props => {
    const { children } = props;
    return <Text fontSize="xl" fontWeight="500">{children}</Text>;
  },
}

export const Recipe = props => {
  const dispatch = useDispatch();

  const { slug } = useParams();
  const details = useSelector((state) => state.recipes.details[slug]);

  useEffect(() => {
    dispatch(queryDetails(slug));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function get_unit_str(unit, quantity) {
    if (unit.visible === false || quantity === 0)
      return ""
    if (quantity > 1)
      return unit.name_plural
    return unit.name
  }

  return details !== undefined ? (
    <Center>
      <Box
        minW={["90vw", "60vw", "60vw", "60vw"]}
        maxW={["90vw", "60vw", "60vw", "60vw"]}
        borderWidth="3px"
        m="5px"
        mt="25px"
        className="drawn_box drawn_box_16"
        backgroundImage="url(/img/bg_card.jpg)"
        backgroundSize="100%"
      >
        <Center>
          <Heading
            fontSize="6xl"
            fontWeight="semibold"
            pt="25px"
          >
            {details.name}
          </Heading>
        </Center>
        <Center>
          <Text
            fontSize="2xl"
            fontWeight="500"
            ml="5px"
            mr="5px"
            textAlign="center"
            mb="10px"
          >
            {details.short_description}
          </Text>
        </Center>
        <Grid
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
            "repeat(2, 1fr)",
            "repeat(2, 1fr)"
          ]}
          gap={6}
          mb="15px"
        >
          <GridItem
            ml={["10px", "50px", "50px", "50px"]}
            mr={["10px", "50px", "50px", "50px"]}
            mt="auto"
            mb="auto"
          >
            <Center>
              <Image
                m="5px"
                src={details.photo_url}
              />
            </Center>
          </GridItem>
          <GridItem
            mt="auto"
            mb="auto"
            ml={["15px", 0, 0, 0]}
          >
            <Heading>
                Ingredients:
            </Heading>
            <UnorderedList>
                {details.items.map((item) => (
                  <ListItem
                    key={item.id}
                    fontSize="xl"
                    fontWeight="500"
                  >
                    {`${item.quantity} ${get_unit_str(item.unit, item.quantity)} ${item.ingredient.name}`}
                  </ListItem>
                ))}
            </UnorderedList>
            <br />
            <Heading>
                Steps:
            </Heading>
            <ReactMarkdown
              components={ChakraUIRenderer(markdownTheme)}
              children={details.steps.replace('\n', '\r')}
            />
          </GridItem>
        </Grid>
      </Box>
    </Center>
  ) : (<></>);
};
