import { persistCombineReducers } from "redux-persist";
import createCompressor from "redux-persist-transform-compress";
import storage from "redux-persist/lib/storage"; // defaults to localStorage

import recipesReducer from "./recipes.js";

const compressor = createCompressor();

const persistConfig = {
  key: "root",
  storage,
  transforms: [compressor],
  whitelist: [], // disable all for now, TODO enable and set key to version
};

const rootReducer = {
  recipes: recipesReducer,
};

export default persistCombineReducers(persistConfig, rootReducer);

