import React from "react";
import { useSelector } from "react-redux";
import {
  Center,
  SimpleGrid,
  Box,
} from "@chakra-ui/react"

import { RecipeCard } from "../components/RecipeCard.js"

export const Home = props => {
  const recipes = Object.values(useSelector((state) => state.recipes.recipes));

  return (
    <Center>
      <SimpleGrid
        columns={[2, 2, 2, 3]}
        w={["95vw", "90vw", "80vw", "60vw"]}
        mt="25px"
        spacing="10px"
        spacingY="20px"
      >
        { recipes.map((recipe) => (
          <Box h="100%" key={"recipe_" + recipe.id}>
            <RecipeCard recipe={recipe} />
          </Box>
        ))}
      </SimpleGrid>
    </Center>
  );
};
